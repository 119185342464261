/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:51:40
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-18 18:56:05
 */
import Procreation from './procreation.vue'
export default Procreation
